<template>
  <div>
    <b-modal
      id="modal-finding-attachments"
      title="Attachments"
      hide-footer
      scrollable
      @show="getMessages"
      size="lg"
    >
      <div id="attachments">
        <div v-if="!attachments.length">No attachments</div>
        <div
          v-for="attachment in attachments"
          :key="attachment.id"
          class="mb-3"
          v-else
        >
          <div>
            <span style="padding: 0; margin: 0">
              <div class="w-100 d-inline-block">
                <span class="message-sender-not-from-me float-left m-0">{{
                  attachment.message_sender_user.user_name
                }}</span>

                <span class="message-sender-from-me float-right m-0">
                  {{ formatTimestamp(attachment.timestamp) }}
                </span>
              </div>
              <div class="d-inline-block">
                <b-icon
                  style="
                    width: max-content;
                    padding-right: 5px;
                    max-width: 25px;
                    color: #2c3e50;
                  "
                  :icon="fileIcon(attachment.message_content)"
                ></b-icon>
                <b-link
                  @click="downloadFile(attachment.message_attachment.s3_key)"
                  style="color: #2c3e50; margin-left: 0 !important"
                  >{{ attachment.message_content }}</b-link
                >
              </div>
            </span>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
// import gql from "graphql-tag";
// const MESSAGES_SUBSCRIPTION = gql`
//   subscription messages($message_finding_id: uuid!) {
//     messages(
//       where: { message_finding_id: { _eq: $message_finding_id } }
//       order_by: { timestamp: asc }
//     ) {
//       id
//       message_content
//       message_is_note
//       message_attachment {
//         id
//         s3_key
//       }
//       message_sender_user {
//         id
//         user_name
//       }
//     }
//   }
// `;
export default {
  name: "AttachmentsModal",
  props: {
    finding: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imagesCount: 0,
      attachments: [],
      testdata: undefined,
      newMessage: "",
      attachment: undefined,
      selectedMessage: undefined,
      options: [
        {
          name: "Delete message",
          class: "delete-message",
        },
      ],
    };
  },
  computed: {
    messageInputClass() {
      if (this.newMessage.length < 40 && !this.newMessage.includes("\n")) {
        return "short";
      } else {
        return "";
      }
    },
  },
  methods: {
    clearFinding() {},
    formatTimestamp(timestamp) {
      const dateObj = new Date(timestamp);
      const time = `${dateObj.getHours()}:${dateObj
        .getMinutes()
        .toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}`;
      const date = `${dateObj.getDate()}/${
        dateObj.getMonth() + 1
      }/${dateObj.getFullYear()}`;
      return `${time} on ${date}`;
    },
    async downloadFile(key) {
      var windowRef = window.open();
      const token = await this.$auth.getTokenSilently();
      axios
        .request({
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            key: key.replace(/\+/g, " "),
          },
          url: "https://api.skyswarm.sky360.com.au/v1/attachments/download",
        })
        .then((response) => {
          windowRef.location.href = response.data.downloadURL;
          if (response.status === 200) {
            this.$vs.notification({
              color: "success",
              duration: "5000",
              text: "Downloading attachment...",
            });
          } else {
            this.$vs.notification({
              color: "danger",
              progress: "auto",
              duration: "8000",
              title: "Uh oh! Something bad has happened...",
              text: "An error occured while downloading the attachment",
            });
          }
        });
    },
    fileIcon(file) {
      let extension = file.split(".").pop();
      switch (extension) {
        case "pdf":
          return "file-earmark-text-fill";
        case "doc":
        case "docx":
          return "file-earmark-word-fill";
        case "jpg":
        case "jpeg":
        case "png":
        case "heic":
          return "image-fill";

        default:
          return "file-earmark-fill";
      }
    },
    sendNotification(color, title, text, duration) {
      this.$vs.notification({
        color: color,
        duration: duration ? duration : 8000,
        title: title,
        text: text,
      });
    },
    messageBubbleClass(message) {
      return message.message_is_note
        ? "message-note"
        : message.message_sender_user.id ===
          this.$auth.user[`https://skyswarm.sky360.com.au/id`]
        ? "message-from-me"
        : "message-not-from-me";
    },
    messagePaddingClass(message) {
      return !["png", "jpg", "jpeg"].includes(
        message.message_content.split(".").pop()
      )
        ? "p-2"
        : "";
    },
    messageSenderClass(sender) {
      return sender.id === this.$auth.user[`https://skyswarm.sky360.com.au/id`]
        ? "message-sender-from-me"
        : "message-sender-not-from-me";
    },
    async getMessages() {
      if (this.finding) {
        const token = await this.$auth.getTokenSilently();
        axios
          .request({
            method: "post",
            url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
            data: {
              query: `query messages {
  messages(where: {message_finding_id: {_eq: "${this.finding.id}"}, message_attachment: {}}, order_by: {timestamp: asc}) {
    id
    message_content
    message_is_note
    timestamp
    message_attachment {
      id
      s3_key
    }
    message_sender_user {
      id
      user_name
      user_company_name
    }
  }
}

`,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.data.errors) {
              this.sendNotification(
                "danger",
                "Uh oh! Something bad has happened...",
                `An error occured loading messages".
              <br><br>
              ${response.data.errors[0].message}`
              );
              return;
            } else {
              this.attachments = response.data.data.messages;
            }
          });
      }
    },
  },
  watch: {
    finding: function () {
      this.getMessages();
    },
  },
};
</script>

<style>
.message-from-me {
  position: relative;
  margin-left: auto;
  background-color: dodgerblue;
  width: max-content;
  max-width: 250px;
  height: max-content;
  text-align: right;
  color: white;
  border-radius: 0.25rem;
}
.message-not-from-me {
  position: relative;
  margin-right: auto;
  background-color: #ebebeb;
  width: max-content;
  max-width: 250px;
  height: max-content;
  text-align: left;
  border-radius: 0.25rem;
}
.message-note {
  position: relative;
  margin-top: 10px;
  padding: 10px;
  width: max-content;
  max-width: 300px;
  height: max-content;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  opacity: 75%;
  font-size: 85%;
}
.message-sender-from-me {
  position: relative;
  width: max-content;
  max-width: 300px;
  height: max-content;
  text-align: center;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 5px;
  color: black;
  font-size: 85%;
}
.message-sender-not-from-me {
  position: relative;
  width: max-content;
  max-width: 300px;
  height: max-content;
  text-align: center;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 5px;
  color: black;
  font-size: 85%;
}
#modal-finding-activity .modal-dialog-scrollable .modal-body {
  flex-direction: column-reverse;
  display: flex;
}
.short {
  max-height: 38px;
}
.modal-dialog-scrollable .modal-content {
  overflow: initial !important;
}
.delete-message {
  color: var(--danger) !important;
}
.delete-message:hover {
  background-color: var(--danger);
  color: white !important;
}
</style>
<template>
  <router-link :to="route" style="color: black" custom>
    <b-icon-chevron-left
      aria-hidden="true"
      style="margin-right: 0.25rem; font-weight: 900"
    ></b-icon-chevron-left
    >{{ name }}
  </router-link>
</template>

<script>
export default {
  name: "BackButton",
  props: {
    name: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
  },
};
</script>